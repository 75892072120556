import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

import getCurrencyIco from '../../../../utils/getCurrencyIco';

import styles from './TotalPayment.module.scss';

const TotalPayment = (props) => {
  const {
    className, value, desc, color,
  } = props;

  return (
    <div className={clsx(className, styles[`color-${color}`])}>
      <div className={styles.title}>
        <NumberFormat
          value={value}
          prefix={`${getCurrencyIco()} `}
          displayType="text"
          decimalSeparator=","
          thousandSeparator=" "
        />
      </div>
      <div className={styles.desc}>{desc}</div>
    </div>
  );
};

TotalPayment.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  desc: PropTypes.string,
  color: PropTypes.string,
};

export default TotalPayment;
