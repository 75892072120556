import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  if (typeof values.email !== 'undefined') {
    if (!values.email) {
      errors.email = i18n.t('form.error-email-required');
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = i18n.t('form.error-email-invalid');
    }
  }
  if (typeof values.password !== 'undefined') {
    if (!values.password) {
      errors.password = i18n.t('form.error-password-required');
    } else if (values.password.length < 8) {
      errors.password = i18n.t('form.error-password-more');
    }
  }
  if (typeof values.iAcceptTermOfService !== 'undefined') {
    if (!values.iAcceptTermOfService) {
      errors.iAcceptTermOfService = true;
    }
  }
  return errors;
};

export default validate;
