import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  SvgIcon,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { mdiBriefcaseClockOutline } from '@mdi/js';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';

import useLabelWidth from '../../../../hooks/useLabelWidth';
import { PaypalIcon } from '../../../../components/Icons/Icons';
import authContext from '../../../../context/auth/authContext';

import PaypalForm from './components/PaypalForm/PaypalForm';
import CardForm from './components/CardForm/CardForm';
import BankForm from './components/BankForm/BankForm';
import PayoutsInfo from './components/PayoutsInfo/PayoutsInfo';

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    & > svg {
      position: relative;
      top: 5px;
    },
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  svgCustomIcon: {
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PayoutsForm = ({ values, handleSetValues }) => {
  const {
    paymentMethod,
  } = values;
  const {
    saveUser,
    userInfo: {
      card,
    },
  } = useContext(authContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPaypalForm, setShowPaypalForm] = useState(false);

  const [
    labelPayoutsWidth,
    labelPayoutsRef,
  ] = useLabelWidth();

  useEffect(() => {
    setShowPaypalForm(false);
  }, [card]);

  const handlerChangePayouts = (event) => {
    handleSetValues({ ...values, paymentMethod: event.target.value });
  };

  const handleChangeInfo = () => {
    setShowPaypalForm(true);
  };

  const handleReset = () => {
    saveUser({ card: null, paymentMethod: 'unavailable' });
  };

  const renderPaymentForm = () => {
    switch (paymentMethod) {
      case 'paypal':
        return (card && !showPaypalForm)
          ? <PayoutsInfo card={card} handleChangeInfo={handleChangeInfo} />
          : <PaypalForm setShowPaypalForm={setShowPaypalForm} />;
      case 'card':
      case 'manual':
      case 'unavailable':
        if (!card) {
          return null;
        }
        return (
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleReset}
          >
            {t('userSettings.account-button-payment-reset')}
          </Button>
        );
      default:
        return <PaypalForm valuesForm={values} handleSetValues={handleSetValues} />;
    }
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={labelPayoutsRef} id="payoutsLabel">{t('userSettings.account-input-label-payouts')}</InputLabel>
          <StyledSelect
            className={classes.select}
            labelId="payoutsLabel"
            value={paymentMethod === 'manual' || paymentMethod === 'card' ? 'unavailable' : paymentMethod}
            defaultValue="unavailable"
            onChange={handlerChangePayouts}
            labelWidth={labelPayoutsWidth}
            displayEmpty
          >
            <MenuItem value="unavailable" className={classes.menuItem}>
              <SvgIcon className={classes.svgCustomIcon}>
                <svg>
                  <path d={mdiBriefcaseClockOutline} />
                </svg>
              </SvgIcon>
              {' '}
              {t('userSettings.account-input-label-not')}
            </MenuItem>
            <MenuItem value="paypal" className={classes.menuItem}>
              <PaypalIcon className={classes.icon} />
              {' '}
              Paypal
            </MenuItem>
            {/* <MenuItem value="manual" className={classes.menuItem}> */}
            {/*  <AccountBalanceOutlinedIcon className={classes.icon} /> */}
            {/*  {' '} */}
            {/*  Bank account */}
            {/* </MenuItem> */}
            {/* <MenuItem value="card" className={classes.menuItem}> */}
            {/*  <CreditCardOutlinedIcon className={classes.icon} /> */}
            {/*  {' '} */}
            {/*  Credit Card */}
            {/* </MenuItem> */}
          </StyledSelect>
        </FormControl>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12}>
        {renderPaymentForm()}
      </Grid>
    </Grid>
  );
};

PayoutsForm.propType = {
  values: PropTypes.shape({
    paymentMethod: PropTypes.string.isRequired,
  }),
  handleSetValues: PropTypes.func.isRequired,
};
export default PayoutsForm;
