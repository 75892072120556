
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import Helmet from 'react-helmet';
import clsx from 'clsx';
import { useHighcharts, useAxis } from 'react-jsx-highstock';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import dateParse from 'date-fns/parse';
import dateFormat from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import 'react-day-picker/lib/style.css';

import 'moment/locale/ru';
import 'moment/locale/de';

import styles from './DateRangePickers.module.scss';

const DAY_FORMAT = 'dd/MM/yyyy';
const ONE_DAY = 86400000;

const parseDate = (str) => {
    let date = dateParse(str, DAY_FORMAT, new Date());
    return !isNaN(date) ? date : new Date();
}
const formatDate = (date) => dateFormat(date, DAY_FORMAT);

const DateRangePickers = ({ lang }) => {
  const Highcharts = useHighcharts();
  const axis = useAxis('xAxis');

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const refTo = useRef(null);
  const modifiers = { start: from, end: to };
  const handleFromDateChange = useCallback((fromDate) => {
    const newMin = startOfDay(fromDate).valueOf();
    const newMax = (newMin >= to) ? newMin + ONE_DAY : to.valueOf();

    axis.setExtremes(newMin, newMax);
  }, [to, axis]);

  const handleToDateChange = useCallback((toDate) => {
    const newMax = startOfDay(toDate).valueOf();
    const newMin = (newMax <= from) ? newMax - ONE_DAY : from.valueOf();

    axis.setExtremes(newMin, newMax);
  }, [from, axis]);

  useEffect(() => {
    if (!axis) return;

    const handleAfterSetExtremes = ({ min, max }) => {
      setFrom(new Date(min));
      setTo(new Date(max));
    };

    Highcharts.addEvent(axis.object, 'afterSetExtremes', handleAfterSetExtremes);
    const { min, max } = axis.getExtremes();
    setFrom(new Date(min));
    setTo(new Date(max));

    return () => {
      Highcharts.removeEvent(axis.object, 'afterSetExtremes', handleAfterSetExtremes);
    };
  }, [axis]);

  if (from === null || to === null) {
    return null;
  }

  return (
    <div className={clsx('InputFromTo', styles['date-range-pickers'])}>
      <DayPickerInput
        value={from}
        format={DAY_FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: { after: to },
          toMonth: to,
          modifiers,
          numberOfMonths: 2,
          onDayClick: () => refTo.current.getInput().focus(),
          locale: lang,
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={handleFromDateChange}
        localeUtils={MomentLocaleUtils}
      />
      {' '}
      —
      {' '}
      <span className="InputFromTo-to">
        <DayPickerInput
          ref={refTo}
          locale={lang}
          value={to}
          format={DAY_FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from },
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 2,
            locale: lang,
            localeUtils: MomentLocaleUtils,
          }}
          onDayChange={handleToDateChange}
          localeUtils={MomentLocaleUtils}
        />
      </span>
      <Helmet>
        <style>
          {`
          .inputFromTo {
            position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1000;}
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 560px;
    margin-left: -185px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -380px;
  }
  .InputFromTo input {
   border: 1px solid #cdcdcd;
    padding: 4px;
    border-radius: 4px;
  }
`}
        </style>
      </Helmet>
    </div>
  );
};

export default DateRangePickers;
