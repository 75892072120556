export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_PRELOADER = 'SHOW_PRELOADER';
export const USER_INFO = 'USER_INFO';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_CHANGE_LANG = 'USER_CHANGE_LANG';
export const USER_CHECK = 'USER_CHECK';
export const GET_EXTERNAL_LOGIN = 'GET_EXTERNAL_LOGIN';
export const SET_COOKIE_ERROR = 'SET_COOKIE_ERROR';

export const SHOW_ALERT = 'SHOW_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const GET_PAYMENTS = 'GET_PAYMENTS';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_TOTAL = 'GET_TOTAL';

export const GET_REWARDS = 'GET_REWARDS';
export const POST_REWARDS = 'POST_REWARDS';
export const SHOW_LOADER_PDF = 'SHOW_LOADER_PDF';
export const HIDE_LOADER_PDF = 'HIDE_LOADER_PDF';
