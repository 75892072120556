import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Preloader.module.scss';
import MainAuthContainer from '../../pages/MainAuthContainer/MainAuthContainer';

const Preloader = ({ show }) => {
  const [hidden, setHidden] = useState(false);
  const preloaderClass = styles.preloader;

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setHidden(true);
      }, 2000);
    }
  }, [show]);

  const renderItems = () => {
    const arr = [];
    let lengthEls = 0;
    while (lengthEls < 13) {
      arr.push(<div className={styles['progress-point']} style={{ animationDelay: `${lengthEls * 0.2}s` }} />);
      lengthEls += 1;
    }
    return arr;
  };
  return (
    <div className={clsx(preloaderClass, hidden && styles['preloader_is-hidden'])}>
      <div className={styles.logo}>
        <svg
          width="200"
          height="200"
          version="1.1"
        >
          <path
            className={styles['app-logo']}
            d="m 99.999591,9.0481702 c -37.905642,0 -68.634259,30.7286008 -68.634259,68.6342508 0.02071,23.764349 12.923374,47.803709 32.544058,58.323829 7.357162,4.4596 17.480234,6.15076 25.280858,2.72231 7.149614,-3.14245 12.313152,-10.79565 12.346812,-20.12566 -0.34044,-16.35204 -13.984606,-22.448897 -30.325,-22.676944 -8.732208,-0.499545 -12.518657,1.984842 -5.254366,-5.458624 3.456644,-3.541937 6.913312,-7.083917 10.369929,-10.625853 4.528283,-4.408591 5.010389,-5.876996 2.989596,-12.404812 -1.463579,-4.727937 -2.927213,-9.455873 -4.390793,-14.18381 -2.710546,-7.846162 -2.650626,-7.316891 4.459851,-3.802433 4.436826,2.192926 8.873673,4.385923 13.310547,6.578849 5.953698,2.840701 7.137646,2.949165 12.721576,-0.989785 4.0442,-2.852868 8.08841,-5.705689 12.13261,-8.55858 6.62452,-5.00253 6.13964,-4.781584 4.99438,3.066728 -0.71474,4.897318 -1.42922,9.794659 -2.1437,14.692019 -0.79541,6.3206 -0.59924,7.699629 4.87256,11.792996 l 11.889,8.893993 c 6.8355,4.766666 6.41397,4.367409 -1.41045,5.694577 l -14.7098,2.495225 c -6.79926,1.302831 -7.261,0.962744 -8.79208,5.548985 -5.40126,16.179419 -11.12911,33.485879 -15.841426,47.674909 h 53.135666 l 15.40953,-46.354088 c -0.0463,0.0086 -0.0951,0.01485 -0.13886,0.02306 2.50069,-7.178895 3.79106,-14.72347 3.81884,-22.325519 0,-37.905674 -30.7286,-68.6342976 -68.63424,-68.6342514 z M 91.631618,160.87011 81.578996,190.95183 h 53.135634 l 10.05267,-30.08172 z"
          />
        </svg>
      </div>
      <div className={styles['progress-wrapper']}>
        {renderItems()}
      </div>
    </div>
  );
};

MainAuthContainer.propType = {
  show: PropTypes.bool.isRequired,
};

export default Preloader;
