import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import NumberFormat from 'react-number-format';
import styles from './InfoBlock.module.scss';
import getCurrencyIco from '../../utils/getCurrencyIco';

const InfoBlock = (props) => {
  const {
    className, icon, value, title, desc, color, currency, descHtml,
  } = props;

  return (
    <div className={clsx(className, styles.main, styles[`color-${color}`])}>
      <div className={styles.body}>
        <div className={styles.title}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title}
        </div>
        {!currency ? (
          <div className={styles.value}>
            {value}
          </div>
        ) : (
          <NumberFormat
            className={styles.value}
            value={value}
            prefix={`${getCurrencyIco()} `}
            displayType="text"
            decimalSeparator=","
            thousandSeparator=" "
          />
        )}
        {descHtml ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: desc }} /> : desc}
      </div>
    </div>
  );
};

InfoBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.element,
  currency: PropTypes.bool,
  value: PropTypes.string,
  desc: PropTypes.string,
  color: PropTypes.string,
  currencyType: PropTypes.bool,
};

export default InfoBlock;
