import React, {
  useContext, useLayoutEffect, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Highcharts from 'highcharts/highstock';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import {
  HighchartsStockChart,
  Chart,
  HighchartsProvider,
  XAxis,
  YAxis,
  ColumnSeries,
  Navigator,
  RangeSelector,
  Tooltip,
} from 'react-jsx-highstock';
import DateRangePickers from './components/DateRangePickers';
import customersContext from '../../../../context/customers/customersContext';
import payoutsContext from '../../../../context/payouts/payoutsContext';
import getCurrencyIco from '../../../../utils/getCurrencyIco';


import styles from './MainChart.module.scss';

const useStyles = makeStyles({
  main: {
    '& .highcharts-navigator-series': {
      display: 'none',
    },
  },
});

const formatDate = (date) => {
  const localTime = new Date(moment.tz(date, moment.tz.guess()).format());
  return localTime.getTime();
};

const formatDataCustomers = (data) => data.map((item) => [formatDate(item.creationDate), 1])
  .filter((item) => item[0] > 0)
  .sort((a, b) => moment(a[0]) - moment(b[0]));

const formatDataPayouts = (data) => data.map((item) => [formatDate(item.paymentDate), item.total])
  .filter((item) => item[0] > 0)
  .sort((a, b) => moment(a[0]) - moment(b[0]));

const createDataPoint = (date) => [
  date,
  0,
];

const createRandomData = (points = 100) => {
  const data = [];
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const diffTime = (31556926 * 1000) / points;
  let i = 0;
  let time = date.getTime();
  for (i; i <= points; i++) {
    time += diffTime;
    data.push(createDataPoint(time, i));
  }
  return data;
};

const MainChart = ({ className, dataCustomers, dataPayouts }) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => { setSelected(2); }, 1000);
  }, [dataCustomers]);

  Highcharts.setOptions({
    lang: i18n.language !== 'en' ? t('highcharts', { returnObjects: true }) : {},
  });

  return (
    <div className={clsx(className, styles.main, classes.main)}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart plotOptions={{
          series: {
            pointInterval: 24 * 3600 * 1000, // one day
            dataGrouping: {
              groupPixelWidth: 50,
              enabled: true,
              forced: true,
              smoothed: true,
              units: [
                ['day', [1]],
                ['week', [1]],
                ['month', [1, 3, 6]],
              ],
            },
          },
        }}
        >
          <Chart zoomType="x" height={550} />
          <Tooltip
            pointFormatter={function tooltipFormatter() {
              return `<div style="margin-top: 6px; color:${this.color}">●</div> ${this.series.name}: <b>${this.y}</b> 
                    ${this.series.userOptions.id === 'payouts' ? getCurrencyIco() : ''}</div>`;
            }}
            dateTimeLabelFormats={{
              millisecond: '%e. %b %Y',
              second: '%e. %b %Y',
              minute: '%e. %b %Y',
              hour: '%e. %b %Y',
              day: '%e. %b %Y',
              week: '%e. %b %Y',
              month: '%b %y',
              year: '%Y',
            }}
          />
          <XAxis
            type="datetime"
            minRange={3600 * 1000}
            dateTimeLabelFormats={{
              millisecond: '%H:%M:%S.%L',
              second: '%H:%M:%S',
              minute: '%H:%M',
              hour: '%H:%M',
              day: '%e. %b',
              week: '%e. %b',
              month: '%b %y',
              year: '%Y',
            }}
          >
            <XAxis.Title>{t('MainChartComponent.line-title')}</XAxis.Title>
          </XAxis>

          <YAxis allowDecimals={false} opposite>
            <YAxis.Title>
              {t('MainChartComponent.y-line-title')}
            </YAxis.Title>
            <ColumnSeries
              id="customers"
              name={t('MainChartComponent.y-line-title')}
              data={
                dataCustomers.length
                  ? formatDataCustomers(dataCustomers)
                  : createRandomData(500)
                    }
            />
          </YAxis>

          <YAxis>
            <YAxis.Title>
              {t('MainChartComponent.y-line-title-opposite')}
              {getCurrencyIco()}
            </YAxis.Title>
            <ColumnSeries
              type="payouts"
              id="payouts"
              name={t('MainChartComponent.y-line-title-opposite')}
              data={
                dataPayouts.length
                  ? formatDataPayouts(dataPayouts)
                  : createRandomData(500)
}
            />
          </YAxis>
          <RangeSelector selected={selected} allButtonsEnabled>
            <RangeSelector.Button count={7} type="day">{t('MainChartComponent.line-7d')}</RangeSelector.Button>
            <RangeSelector.Button count={1} type="month">{t('MainChartComponent.line-1m')}</RangeSelector.Button>
            <RangeSelector.Button count={0} type="all">{t('MainChartComponent.line-all')}</RangeSelector.Button>
          </RangeSelector>

          <DateRangePickers
            lang={i18n.language}
            fromText={t('MainChartComponent.data-range-from')}
            toText={t('MainChartComponent.data-range-to')}
          />
          <Navigator>
            <Navigator.Series seriesId="customers" />
            <Navigator.Series seriesId="payouts" />
          </Navigator>
        </HighchartsStockChart>
      </HighchartsProvider>
    </div>
  );
};

export default MainChart;
