import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography, Button, FormHelperText, Link,
} from '@material-ui/core';
import {
  AlternateEmail,
  LockOpen,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';

import useLabelWidth from '../../../../hooks/useLabelWidth';
import Input from '../../../../components/Input/Input';
import useForm from '../../../../hooks/useForm';
import validate from '../../../../helpers/validate';
import useKeyPress from '../../../../hooks/useKeyPress';
import passGenerate from '../../../../utils/passGenerate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
    marginTop: theme.spacing(6),
  },
  divider: {
    marginBottom: 20,
  },
  formLink: {
    cursor: 'pointer',
  },
}));


const NewCustomerForm = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const [
    labelPassWidth,
    labelPassRef,
  ] = useLabelWidth();

  const classes = useStyles();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    console.log('NewCustomer send');
  }, validate, {
    password: '',
    email: '',
  });

  useKeyPress('Enter', handleSubmit);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGenerate = () => {
    handleChange({ target: { name: 'password', value: passGenerate(8) } });
    setShowPassword(true);
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12}>
        <Input
          className={classes.formControl}
          value={values.email || ''}
          name="email"
          labelText={t('newCustomerPage.input-label-email')}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          startAdornment={(
            <InputAdornment position="start">
              <AlternateEmail color="disabled" />
            </InputAdornment>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} variant="outlined" error={!!errors.password}>
          <InputLabel ref={labelPassRef} htmlFor="outlined-adornment-password">{t('newCustomerPage.input-label-password')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            name="password"
            onChange={handleChange}
            labelWidth={labelPassWidth}
            startAdornment={(
              <InputAdornment position="start">
                <LockOpen color="disabled" />
              </InputAdornment>
                      )}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
                      )}
          />
          <FormHelperText>{errors.password}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <Link
            className={classes.formLink}
            onClick={handleGenerate}
          >
            {t('newCustomerPage.form-link')}
          </Link>
          {t('newCustomerPage.form-desc')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('newCustomerPage.button-add')}
        </Button>
      </Grid>
    </Grid>
  );
};

NewCustomerForm.propType = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
};
export default NewCustomerForm;
