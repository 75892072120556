import { useEffect, useRef } from 'react';

const useKeyPress = (key, cb) => {
  const callbackRef = useRef(cb);
  useEffect(() => {
    callbackRef.current = cb;
  });
  useEffect(() => {
    function handler(event) {
      if (key === event.code) {
        callbackRef.current(event);
      }
    }

    document.addEventListener('keypress', handler);
    return () => document.removeEventListener('keypress', handler);
  }, [key]);
};

export default useKeyPress;
