import React, {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button, InputLabel, OutlinedInput, FormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import Input from '../../../../../../components/Input/Input';
import useLabelWidth from '../../../../../../hooks/useLabelWidth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  firstInput: {
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
}));


const CardForm = ({ values, handleSetValues }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    company,
  } = values;
  const [
    labelCompanyWidth,
    labelCompanyRef,
  ] = useLabelWidth();

  const handlerChangeCompany = (event) => {
    handleSetValues({ ...values, company: event.target.value });
  };
  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={6}>
        <Input
          className={classes.formControl}
          labelText="Card number"
          value=""
          onChange={handlerChangeCompany}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          labelText="Expiration date"
          value=""
          onChange={handlerChangeCompany}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          className={classes.formControl}
          labelText="CVC"
          value=""
          onChange={handlerChangeCompany}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
        >
          Use this credit card
        </Button>
      </Grid>
    </Grid>
  );
};

CardForm.propType = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    websiteFaculty: PropTypes.string.isRequired,
  }),
  handleSetValues: PropTypes.func.isRequired,
};
export default CardForm;
