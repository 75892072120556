import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Link,
} from '@material-ui/core';
import styles from './PayoutsInfo.module.scss';


const PayoutsInfo = ({ card, handleChangeInfo }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.email}>{card.payerID}</div>
      <Link
        component="button"
        className={styles.link}
        color="inherit"
        onClick={handleChangeInfo}
        underline="always"
      >
        Change PayPal account
      </Link>
    </div>
  );
};

PayoutsInfo.propType = {
  card: PropTypes.object.isRequired,
};
export default PayoutsInfo;
