import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import TotalPayment from './components/TotalPayment/TotalPayment';

import styles from './TotalPayments.module.scss';

const TotalPayments = (props) => {
  const {
    className, value,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={className}>
      <TotalPayment className={styles.totalPayment} value={value.total} desc={t('TotalPaymentsComponent.total-payments')} />
      <TotalPayment className={styles.totalPayment} value={value.reward} desc={t('TotalPaymentsComponent.total-reward')} />
      <TotalPayment className={styles.totalPayment} value={value.rewarded} desc={t('TotalPaymentsComponent.total-payouts')} />
      <TotalPayment className={styles.totalPayment} color="green" value={value.unReward} desc={t('TotalPaymentsComponent.reward-availabel')} />
    </div>
  );
};

TotalPayments.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

export default TotalPayments;
