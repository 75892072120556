import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  InputAdornment, Link,
} from '@material-ui/core';
import { AlternateEmail } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import useForm from '../../../../../../hooks/useForm';
import validate from '../../../../../../helpers/validate';
import Input from '../../../../../../components/Input/Input';
import authContext from '../../../../../../context/auth/authContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
}));


const PaypalForm = ({ setShowPaypalForm }) => {
  const {
    saveUser,
    userInfo:
      {
        card,
      },
  } = useContext(authContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const payerID = card && card.payerID;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    saveUser({ card: { payerID: values.email }, paymentMethod: 'paypal' });
  }, validate, {
    email: payerID || '',
  });

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={6}>
        <Input
          className={classes.formControl}
          value={values.email || ''}
          name="email"
          labelText={t('paypalForm.input-label-email')}
          onChange={handleChange}
          error={!!errors.email}
          helperTextError={errors.email}
          startAdornment={(
            <InputAdornment position="start">
              <AlternateEmail color="disabled" />
            </InputAdornment>
            )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('paypalForm.button-save')}
        </Button>
        {card && (
        <Link
          variant="body2"
          component="button"
          onClick={() => {
            setShowPaypalForm(false);
          }}
        >
          {t('userSettings.account-button-cancel')}
        </Link>
        )}
      </Grid>
    </Grid>
  );
};

PaypalForm.propType = {
  setShowPaypalForm: PropTypes.func.isRequired,
};
export default PaypalForm;
