import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import alertReducer from './alertReducer';
import AlertContext from './alertContext';
import { SHOW_ALERT, CLOSE_ALERT } from '../types';

const AlertState = ({ children }) => {
  const initialState = {
    show: false,
    message: '',
    typeAlert: 'error',
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  const showAlert = (message, type) => dispatch({ type: SHOW_ALERT, payload: { message, type } });
  const closesAlert = () => dispatch({ type: CLOSE_ALERT });

  return (
    <AlertContext.Provider value={{
      showAlert,
      closesAlert,
      isShowAlert: state.show,
      messageAlert: state.message,
      typeAlert: state.typeAlert,
    }}
    >
      {children}
    </AlertContext.Provider>
  );
};

AlertState.propType = {
  children: PropTypes.element.isRequired,
};

export default AlertState;
