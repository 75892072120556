import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@material-ui/core';

import clsx from 'clsx';
import useLabelWidth from '../../hooks/useLabelWidth';

import styles from './SelectCountries.module.scss';

const SelectCountries = ({
  className, value, name, labelText, helperText, onChange, startAdornment, error, required,
}) => {
  const { t } = useTranslation();
  const [
    labelWidth,
    labelRef,
  ] = useLabelWidth();
  const [countriesArray, setCountriesArray] = useState([]);

  useEffect(() => {
    const countries = [];
    const keys = t('countries', { returnObjects: true });
    for (const key in keys) {
      countries.push({
        code: key,
        title: keys[key],
      });
    }

    setCountriesArray(countries);
  }, []);

  return (
    <FormControl required={required} className={className} variant="outlined" error={error}>
      <InputLabel ref={labelRef} htmlFor={`input-${name}`}>{labelText}</InputLabel>
      <Select
        value={value || ''}
        id={`input-${name}`}
        name={name}
        onChange={onChange}
        labelWidth={labelWidth}
        startAdornment={startAdornment}
      >
        {countriesArray.map((item) => <MenuItem value={item.code}>{item.title}</MenuItem>)}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectCountries;
