import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinearProgress from '@material-ui/core/LinearProgress';

import AuthContext from '../../context/auth/authContext';

import Copyright from '../../components/Copyright/Copyright';
import LogoIcon from '../../components/Icons/Icons';

import MainNavigation from './components/MainNavigation/MainNavigation';

import styles from './MainContainer.module.scss';

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#fce7ee!important',
    },
    '.material-table tr:last-child': {
      height: 'auto!important',
    },
    '.material-table tr:not(.MuiTableRow-hover):last-child td': {
      border: 'none'
    }
  },
})(() => null);

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    marginLeft: 0,
    paddingRight: 2,
    justifyContent: 'flex-start',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '70px',
  },
  toolbarShift: {
    marginLeft: -drawerWidth,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    boxShadow: theme.shadows[7],
    border: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: drawerWidth,
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  alert: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgb(255, 244, 229)',
  },
  alertLink: {
    fontSize: 'inherit',
    cursor: 'pointer',
  },
}));

const MainContainer = withRouter(({ children, location }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loading, reSendConfirmUserEmail, userInfo: {
      emailConfirmed,
      card,
      firstName,
      email,
    },
  } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [showMessageLoader, setShowMessageLoader] = useState(false);
  const [sendConfirmEmail, setSendConfirmEmail] = useState(false);

  useEffect(() => {
    setSendConfirmEmail(false);
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleConfirmEmail = () => {
    setShowMessageLoader(true);
    reSendConfirmUserEmail().then((data) => {
      setTimeout(() => {
        if (data.data.success) {
          setSendConfirmEmail(true);
        }
        setShowMessageLoader(false);
      }, 2000);
    });
  };

  return (
    <div className={classes.root}>
      <GlobalCss />
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={clsx(classes.toolbar, !open && classes.toolbarShift)}>

            <LogoIcon fontSize="large" />

            <div className={styles['logo-desc']}>
              <div>Questionstar</div>
              <div>{t('mainContainer.logo-desc')}</div>
            </div>

            {!open ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

          </Toolbar>
        </AppBar>

        <MainNavigation
          userName={firstName || email}
          className={styles['main-navigation']}
          isOpen={open}
        />

      </Drawer>

      <main className={classes.content} id="mainContainer">
        {loading && <LinearProgress className={classes.progressBar} variant="query" color="primary" />}
        <Container maxWidth={false} className={classes.container}>
          <Grid container>
            {!emailConfirmed
            && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="outlined" className={classes.alert} icon={showMessageLoader && <CircularProgress color="primary" size={20} />}>
                {!sendConfirmEmail
                  ? !showMessageLoader ? (
                    <div>
                      {t('mainContainer.email-activation-alert-message')}
                      <Link className={classes.alertLink} onClick={handleConfirmEmail}>
                        {t('mainContainer.email-activation-alert-message-link') }
                      </Link>
                    </div>
                  ) : (
                    <div>
                      {t('mainContainer.email-activation-alert-message-loading') }
                    </div>
                  )
                  : (
                    <div>
                      {t('mainContainer.email-activation-sent-message1', { email })}
                      <Link to="/profile" component={NavLink}>{t('mainContainer.email-activation-sent-message2')}</Link>
                      .
                    </div>
                  )}
              </Alert>
            </Grid>
            )}
            {!card && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="outlined" className={classes.alert}>
                <AlertTitle>{t('mainContainer.account-activation-alert-title')}</AlertTitle>
                {t('mainContainer.account-activation-alert-message1')}
                <Link to="/account" component={NavLink}>{t('mainContainer.account-activation-alert-link')}</Link>
                {t('mainContainer.account-activation-alert-message2')}
              </Alert>
            </Grid>
            )}
            { children }
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
});

MainContainer.propType = {
  children: PropTypes.element.isRequired,
};

export default MainContainer;
