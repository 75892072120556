import { GET_CUSTOMERS, GET_TOTAL } from '../types';

const handlers = {
  [GET_CUSTOMERS]: (state, { payload }) => ({
    ...state,
    loading: false,
    data: payload,
  }),
  [GET_TOTAL]: (state, { payload }) => ({
    ...state,
    loading: false,
    totals: payload,
  }),
};

const customersReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default customersReducer;
