import { SHOW_ALERT, CLOSE_ALERT } from '../types';

const handlers = {
  [SHOW_ALERT]: (state, { payload }) => ({
    ...state,
    show: true,
    message: payload.message,
    typeAlert: payload.type,
  }),
  [CLOSE_ALERT]: (state) => ({ ...state, show: false }),
};

const alertReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default alertReducer;
