import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  Link as LinkIcon,
  AlternateEmail,
  Language,
} from '@material-ui/icons';

import useLabelWidth from '../../../../hooks/useLabelWidth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
}));


const ProfileForm = ({ values, handleSetValues }) => {
  const {
    email,
    language,
    websiteFaculty,
  } = values;

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const [
    labelLanguageWidth,
    labelLanguageRef,
  ] = useLabelWidth();

  const [
    labelWebsiteFacultyWidth,
    labelWebsiteFacultyRef,
  ] = useLabelWidth();

  const [
    labelEmailWidth,
    labelEmailRef,
  ] = useLabelWidth();

  const classes = useStyles();

  const handleChange = (prop) => (event) => {
    handleSetValues({ ...values, [prop]: event.target.value });
  };

  const handlerChangeLang = (event) => {
    handleSetValues({ ...values, language: event.target.value });
  };

  const handlerChangeSite = (event) => {
    handleSetValues({ ...values, websiteFaculty: event.target.value });
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={labelLanguageRef} id="langLabel">{t('userSettings.settings-input-label-lang')}</InputLabel>
          <Select
            labelId="langLabel"
            label="Language"
            value={language}
            onChange={handlerChangeLang}
            labelWidth={labelLanguageWidth}
            startAdornment={(
              <InputAdornment position="start">
                <Language color="disabled" />
              </InputAdornment>
                      )}
          >
            <MenuItem value="ru">Russian</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="de">Deutsch</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} variant="outlined" fullWidth>
          <InputLabel ref={labelWebsiteFacultyRef} htmlFor="input-with-icon-adornment">{t('userSettings.settings-input-label-website-faculty')}</InputLabel>
          <OutlinedInput
            value={websiteFaculty}
            id="input-with-icon-adornment"
            onChange={handlerChangeSite}
            labelWidth={labelWebsiteFacultyWidth}
            startAdornment={(
              <InputAdornment position="start"><LinkIcon color="disabled" /></InputAdornment>
                      )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} variant="outlined" fullWidth>
          <InputLabel ref={labelEmailRef} htmlFor="input-with-icon-adornment">{t('userSettings.settings-input-label-email')}</InputLabel>
          <OutlinedInput
            value={email}
            id="input-with-icon-adornment"
            disabled
            labelWidth={labelEmailWidth}
            startAdornment={(
              <InputAdornment position="start">
                <AlternateEmail color="disabled" />
              </InputAdornment>
                      )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

ProfileForm.propType = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    websiteFaculty: PropTypes.string.isRequired,
  }),
  handleSetValues: PropTypes.func.isRequired,
};
export default ProfileForm;
