import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@material-ui/core';
import {
  AlternateEmail, LockOpen, VisibilityOffOutlined, VisibilityOutlined,
} from '@material-ui/icons';

import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import useForm from '../../hooks/useForm';
import useQuery from '../../hooks/useQuery';
import validate from '../../helpers/validate';
import AuthContext from '../../context/auth/authContext';
import useLabelWidth from '../../hooks/useLabelWidth';
import useKeyPress from '../../hooks/useKeyPress';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  divider: {
    marginBottom: '10px',
  },
  gridLang: {
    textAlign: 'right',
  },
  selctLang: {
    fontSize: '12px',
  },
  errorMess: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const ResetPass = () => {
  const { t } = useTranslation();
  const { reset, loading } = useContext(AuthContext);
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const [
    labelEmailWidth,
    labelEmailRef,
  ] = useLabelWidth();

  const [
    labelPassWidth,
    labelPassRef,
  ] = useLabelWidth();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    if (query.get('code') && query.get('email')) {
      reset(query.get('code').replace(
        new RegExp(/ /g), '+',
      ),
      query.get('email'),
      values.password).then((data) => {
        if (!data.data.success) {
          setShowFormError(true);
        } else {
          setShowFormError(false);
          history.push(`/sign-in?email=${query.get('email')}`);
        }
      });
    }
  }, validate, {
    password: '',
  });

  useKeyPress('Enter', handleSubmit);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.paper}>
      <Meta
        title={t('meta.reset-pass')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <div className={classes.form}>
        <FormControl className={classes.formControl} variant="outlined" fullWidth error={!!errors.email}>
          <InputLabel ref={labelEmailRef} htmlFor="input-with-icon-adornment">{t('resetPassPage.input-label-email')}</InputLabel>
          <OutlinedInput
            value={query.get('email')}
            id="input-with-icon-adornment"
            name="email"
            disabled
            onChange={handleChange}
            labelWidth={labelEmailWidth}
            startAdornment={(
              <InputAdornment position="start">
                <AlternateEmail color="disabled" />
              </InputAdornment>
                              )}
          />
          <FormHelperText>{errors.email}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined" error={!!errors.password}>
          <InputLabel ref={labelPassRef} htmlFor="outlined-adornment-password">{t('resetPassPage.input-label-password')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={values.password || ''}
            onChange={handleChange}
            name="password"
            labelWidth={labelPassWidth}
            startAdornment={(
              <InputAdornment position="start">
                <LockOpen color="disabled" />
              </InputAdornment>
                              )}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
                              )}
          />
          <FormHelperText>{errors.password}</FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t('resetPassPage.button-reset')}
        </Button>
        {showFormError
        && (
        <div className={classes.errorMess}><Alert severity="error" color="error">{t('resetPassPage.error-form')}</Alert></div>
        )}
      </div>
    </div>

  );
};

export default ResetPass;
