import { post, put, get } from './restApi';

export const userLogin = (data) => post('Account/Login', { ...data, grant_type: 'password' });

export const registerExternalToken = (data) => post('Account/RegisterExternalToken', { ...data });

export const userRegister = (data) => post('Account/Register', { ...data });

export const userLogOut = () => post('Account/Session/Logout');

export const recoverPassword = (data) => post('Account/ForgotPassword', { ...data, callbackUrl: window.location.origin + '/reset-pass' });

export const resetPassword = (data) => put('Account/ResetPassword', { ...data });

export const checkUserAuth = () => put('Session/Sessions/HeartBeat');

export const getUserInfo = () => get('Partner/Partners/Partner');

export const updateUserInfo = (data) => put('Partner/Partners/UpdatePartnerInfo', { ...data });

export const externalLogins = () => get('Account/ExternalLogins?returnUrl=/');

export const confirmEmail = (code, userid) => put('Account/ConfirmEmail', { code, userid });

export const reSendConfirmEmail = () => post('Account/ReSendConfirmEmail', `\"${window.location.origin}/confirm-email\"`, {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});
