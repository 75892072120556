import React, { useContext, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  InputAdornment,
  Divider, Button,
} from '@material-ui/core';
import {
  Business,
} from '@material-ui/icons';

import Input from '../../../../components/Input/Input';
import ConfirmationForm from '../../../../components/ConfirmationForm/ConfirmationForm';
import SelectCountries from '../../../../components/SelectCountries/SelectCountries';
import AuthContext from '../../../../context/auth/authContext';
import useForm from '../../../../hooks/useForm';
import validate from '../../../../helpers/validateContactForm';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  firstInput: {
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  checkbox: {
    marginBottom: theme.spacing(4),
  },
}));


const ContactForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    saveUser,
    userInfo,
  } = useContext(AuthContext);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    saveUser(values);
  }, validate, {
    company: userInfo.company,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    address: userInfo.address,
    postalCode: userInfo.postalCode,
    city: userInfo.city,
    country: userInfo.country,
    phone: userInfo.phone,
    iAcceptTermOfService: userInfo.iAcceptTermOfService,
  }, (errors) => {
      if(Object.keys(errors).length !== 0) {
          document.querySelector('#mainContainer').scrollTo({
              top: document.querySelector(`.to-scroll-${Object.keys(errors)[0]}`).offsetTop - 40,
              behavior: "smooth"
          })
      }
  });
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      handleChange({ target: { name: 'iAcceptTermOfService', checked: false, type: 'checkbox' } });
    }
  }, [
    values.company,
    values.firstName,
    values.lastName,
    values.address,
    values.postalCode,
    values.city,
    values.country,
    values.phone,
  ]);

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12}>
        <Input
          className={classes.formControl}
          value={values.company || ''}
          name="company"
          labelText={t('contactForm.input-label-company-name')}
          onChange={handleChange}
          startAdornment={(
            <InputAdornment position="start">
              <Business color="disabled" />
            </InputAdornment>
              )}
        />
      </Grid>
      <Grid item xs={6} className="to-scroll-firstName">
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.firstName || ''}
          name="firstName"
          labelText={t('contactForm.input-label-first-name')}
          onChange={handleChange}
          error={!!errors.firstName}
          helperTextError={errors.firstName}
          required
        />
      </Grid>
      <Grid item xs={6} className="to-scroll-lastName">
        <Input
          className={classes.formControl}
          value={values.lastName || ''}
          name="lastName"
          labelText={t('contactForm.input-label-last-name')}
          onChange={handleChange}
          error={!!errors.lastName}
          helperTextError={errors.lastName}
          required
        />
      </Grid>
      <Grid item xs={12} className="to-scroll-address">
        <Input
          className={classes.formControl}
          value={values.address || ''}
          name="address"
          labelText={t('contactForm.input-label-address')}
          onChange={handleChange}
          error={!!errors.address}
          helperTextError={errors.address}
          required
        />
      </Grid>
      <Grid item xs={6} className="to-scroll-postalCode">
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.postalCode || ''}
          name="postalCode"
          labelText={t('contactForm.input-label-postal-code')}
          onChange={handleChange}
          error={!!errors.postalCode}
          helperTextError={errors.postalCode}
          required
        />
      </Grid>
      <Grid item xs={6} className="to-scroll-city">
        <Input
          className={classes.formControl}
          value={values.city || ''}
          name="city"
          labelText={t('contactForm.input-label-city')}
          onChange={handleChange}
          error={!!errors.city}
          helperTextError={errors.city}
          required
        />
      </Grid>
      <Grid item xs={6} className="to-scroll-country">
        <SelectCountries
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.country || ''}
          name="country"
          labelText={t('contactForm.input-label-country')}
          onChange={handleChange}
          error={!!errors.country}
          helperTextError={errors.country}
          required
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6} className="to-scroll-phone">
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.phone || ''}
          name="phone"
          labelText={t('contactForm.input-label-phone')}
          onChange={handleChange}
          helperText={t('contactForm.phone-desc')}
          error={!!errors.phone}
          helperTextError={errors.phone}
          required
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} className="to-scroll-iAcceptTermOfService">
        <ConfirmationForm
          name="iAcceptTermOfService"
          onChange={handleChange}
          error={errors.iAcceptTermOfService}
          checked={values.iAcceptTermOfService}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('contactForm.button-save')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
