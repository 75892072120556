import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Facebook as FacebookIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { GooglelIcon } from '../Icons/Icons';
import AuthContext from '../../context/auth/authContext';

import styles from './SocialLogin.module.scss';

const SocialLogins = (props) => {
  const {
    googleButtonText,
    fbButtonText,
    onError,
    cookieError
  } = props;
  const { t } = useTranslation();
  const {
    getExternalLogins, googleLogin, fbLogin, externalLogins,
  } = useContext(AuthContext);

  const [cookieErrorStatus, setCookieErrorStatus] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    getExternalLogins();
  }, []);

  const handleGoogleReq = (data) => {
    if (data?.error === 'idpiframe_initialization_failed') {
      setCookieErrorStatus(data);
      return;
    }
    const res = googleLogin(data);
    res.then((req) => {
      if (onError && req.isAxiosError) {
        onError(req.response);
      }
    });
  };

  const handleFbReq = (data) => {
    const res = fbLogin(data);
    res.then((req) => {
      if (req && onError && req.isAxiosError) {
        onError(req.response);
      }
    });
  };

  const handleGoogleButton = (onClick) => {
    if (cookieErrorStatus || cookieError) {
      setButtonDisabled(true);
      onError({
        error: 'idpiframe_initialization_failed'
      });
    } else {
      onClick();
    }
  };

  return (
    <div>
      {externalLogins && externalLogins.map((item) => {
        if (item.type === 'Google') {
          return (
            <GoogleLogin
              clientId={item.clientId}
              onSuccess={handleGoogleReq}
              onFailure={handleGoogleReq}
              redirectUri="/"
              cookiePolicy="single_host_origin"
              disabled={buttonDisabled}
              onClick={handleGoogleButton}
              render={(renderProps) => (
                <button className={styles['google-button']} type="submit" onClick={() => { handleGoogleButton(renderProps.onClick); }} disabled={renderProps.disabled}>
                  <GooglelIcon fontSize="inherit" className={styles['google-icon']} />
                  {googleButtonText || t('signInPage.button-google')}
                </button>
              )}
            />
          );
        }

        if (item.type === 'Facebook') {
          return (
            <FacebookLogin
              appId={item.clientId}
              textButton={fbButtonText || t('signInPage.button-facebook')}
              fields="name,email,picture"
              onClick={handleFbReq}
              callback={handleFbReq}
              cssClass={styles['facebook-button']}
              icon={<FacebookIcon fontSize="small" className={styles['facebook-button_icon']} />}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

SocialLogins.propType = {
  googleButtonText: PropTypes.string,
  fbButtonText: PropTypes.string,
};

export default SocialLogins;
