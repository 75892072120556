import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox, FormControlLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(4),
  },
  controlLabel: {
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 12,
    '& > a': {
      color: theme.palette.primary.main,
    },
    '& > a:hover': {
      textDecoration: 'none',
    },
  },
}));

const ConfirmationForm = ({
  onChange, value, checked, name, error,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <FormControl required error={error} component="fieldset" className={classes.formControl}>
      <FormControlLabel
        className={classes.controlLabel}
        name={name}
        control={<Checkbox color="primary" required />}
        label={<span className={classes.label} dangerouslySetInnerHTML={{ __html: t('ConfirmationFormComponent.label') }} />}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      {error && <FormHelperText error>{t('ConfirmationFormComponent.error')}</FormHelperText>}
    </FormControl>
  );
};

export default ConfirmationForm;
