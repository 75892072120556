import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import NewCustomerForm from './components/NewCustomerForm/NewCustomerForm';

import styles from './NewCustomer.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 20,
  },
}));

const NewCustomer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-new-customer')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom>{t('newCustomerPage.page-title')}</Typography>
          <Divider className={classes.divider} />
          <NewCustomerForm />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewCustomer;
