import React from 'react';
import {
  FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput,
} from '@material-ui/core';

import useLabelWidth from '../../hooks/useLabelWidth';

import styles from './Input.module.scss';

const Input = ({
  className, value, name, labelText, helperText, helperTextError, onChange, startAdornment, error, required,
}) => {
  const [
    labelWidth,
    labelRef,
  ] = useLabelWidth();

  return (
    <FormControl required={required} className={className} variant="outlined" error={error}>
      <InputLabel ref={labelRef} htmlFor={`input-${name}`}>{labelText}</InputLabel>
      <OutlinedInput
        value={value || ''}
        id={`input-${name}`}
        name={name}
        onChange={onChange}
        labelWidth={labelWidth}
        startAdornment={startAdornment}
      />
      <FormHelperText error>{helperTextError}</FormHelperText>
      <FormHelperText error={false}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Input;
