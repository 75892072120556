import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import {
  HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Title, Subtitle, Legend, AreaSplineSeries, Navigator,
} from 'react-jsx-highstock';
import DateRangePickers from './DateRangePickers';
import './index.css';

const createDataPoint = (time = Date.now(), magnitude = 1000, offset = 0) => [
  time + offset * magnitude,
  Math.round((Math.random() * 100) * 2) / 2,
];

const createRandomData = (time, magnitude, points = 100) => {
  const data = [];
  let i = (points * -1) + 1;
  for (i; i <= 0; i++) {
    data.push(createDataPoint(time, magnitude, i));
  }
  return data;
};

const addDataPoint = (data, toAdd) => {
  if (!toAdd) toAdd = createDataPoint();
  const newData = data.slice(0); // Clone
  newData.push(toAdd);
  return newData;
};


class App extends Component {
  constructor(props) {
    super(props);

    const now = Date.now();
    this.state = {
      data1: createRandomData(now, 1e8),
    };
  }

  render() {
    const { data1 } = this.state;

    return (
      <div className="app">
        <HighchartsStockChart className="custom-component-chart">
          <Chart zoomType="x" />

          <Title>Custom Components</Title>

          <Subtitle>react-day-picker Date Pickers</Subtitle>

          <Legend>
            <Legend.Title>Key</Legend.Title>
          </Legend>

          <XAxis>
            <XAxis.Title>Time</XAxis.Title>
          </XAxis>

          <YAxis>
            <YAxis.Title>Price</YAxis.Title>
            <AreaSplineSeries id="profit" name="Profit" data={data1} />
          </YAxis>

          <DateRangePickers />

          <Navigator>
            <Navigator.Series seriesId="profit" />
          </Navigator>
        </HighchartsStockChart>

      </div>
    );
  }
}

export default withHighcharts(App, Highcharts);
