import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Link,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import styles from './Advertising.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 20,
  },
}));

const Advertising = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const getPDFLink = () => {
    if (i18n.language === 'ru') return '/doc/Правила_рекламы_для_партнеров.pdf';

    if (i18n.language === 'de') return '/doc/Werberegeln.pdf';

    if (i18n.language === 'en') return '/doc/Advertisement_Rules.pdf';
    return null;
  };

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-advertising')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom>{t('advertisingPage.page-title')}</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6">{t('advertisingPage.allowed-list')}</Typography>

          <ol>
            <li>{t('advertisingPage.allowed-list-1')}</li>
            <li>{t('advertisingPage.allowed-list-2')}</li>
            <li>{t('advertisingPage.allowed-list-3')}</li>
          </ol>

          <Typography variant="h6">{t('advertisingPage.not-allowed-list')}</Typography>

          <ol className={styles.lastList}>
            <li>{t('advertisingPage.not-allowed-list-1')}</li>
            <li>{t('advertisingPage.not-allowed-list-2')}</li>
            <li>{t('advertisingPage.not-allowed-list-3')}</li>
            <li>{t('advertisingPage.not-allowed-list-4')}</li>
            <li>{t('advertisingPage.not-allowed-list-5')}</li>
          </ol>

          <div>
            <Link href={getPDFLink()} target="_blink">{t('advertisingPage.download-pdf')}</Link>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Advertising;
