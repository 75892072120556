import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button, InputLabel, OutlinedInput, FormControl, FormHelperText,
} from '@material-ui/core';
import useLabelWidth from '../../../../../../hooks/useLabelWidth';
import useForm from "../../../../../../hooks/useForm";
import validate from "../../../../../../helpers/validate";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  firstInput: {
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
}));


const CardForm = ({ valuesForm, handleSetValues }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [
    labelHolderWidth,
    labelHolderRef,
  ] = useLabelWidth();

  const [
    labelIbanWidth,
    labelIbanRef,
  ] = useLabelWidth();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    console.log('bank send');
  }, validate, {
    holder: '',
    iban: '',
  });

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl} error={!!errors.holder}>
          <InputLabel ref={labelHolderRef} htmlFor="input-account-holder">{t('bankForm.input-label-holder')}</InputLabel>
          <OutlinedInput
            value={values.holder}
            name="holder"
            id="input-account-holder"
            onChange={handleChange}
            labelWidth={labelHolderWidth}
          />
        </FormControl>
        <FormHelperText>{errors.holder}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl} error={!!errors.iban}>
          <InputLabel ref={labelIbanRef} htmlFor="input-account-iban">IBAN</InputLabel>
          <OutlinedInput
            value={values.iban}
            id="input-account-iban"
            name="iban"
            onChange={handleChange}
            labelWidth={labelIbanWidth}
          />
          <FormHelperText>{errors.iban}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('bankForm.button-save')}
        </Button>
      </Grid>
    </Grid>
  );
};

CardForm.propType = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    websiteFaculty: PropTypes.string.isRequired,
  }),
  handleSetValues: PropTypes.func.isRequired,
};
export default CardForm;
