import React, {
  useContext, useEffect, useLayoutEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins from '@amcharts/amcharts4/plugins/rangeSelector';
import am4langRU from '@amcharts/amcharts4/lang/ru_RU.js';
import am4langDE from '@amcharts/amcharts4/lang/de_DE.js';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import customersContext from '../../../../context/customers/customersContext';
import payoutsContext from '../../../../context/payouts/payoutsContext';

am4core.useTheme(am4themesAnimated);

const formatDate = (date) => {
  const utcTime = new Date(moment.tz(date, moment.tz.guess()).format());
  const newDate = new Date(utcTime.getFullYear(), utcTime.getMonth(), utcTime.getDay()).getTime();
  return newDate;
};

const formatDataCustomers = (data) => data.map((item) => ({
  date: formatDate(item.creationDate),
  value: 1,
}))
  .filter((item) => item.date > 0)
  .sort((a, b) => moment(a.date) - moment(b.date));

const formatDataPayouts = (data) => data.map((item) => ({
  date: formatDate(item.paymentDate),
  value: item.total,
}))
  .filter((item) => item.date > 0)
  .sort((a, b) => moment(a.date) - moment(b.date));

const createDataPoint = (date) => [
  date,
  0,
];

const createRandomData = (points = 100) => {
  const data = [];
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const diffTime = (31556926 * 1000) / points;
  let i = 0;
  let time = date.getTime();
  for (i; i <= points; i++) {
    time += diffTime;
    data.push(createDataPoint(time, i));
  }
  return data;
};

const testCustomerData = [
  {
    creationDate: '2018-12-25T12:49:36.7723644+00:00',
    email: 't***7@mail.ru',
    expirationDate: null,
    fullName: 's*s ',
    id: 'ee8af8ae-7039-491c-9241-b81f484421a8',
    metaType: 'customer',
    number: 100,
    product: 'FREE',
    reward: 0,
    total: 0,
    unReward: 0,
  },
];

const testPayData = [
  {
    creationDate: '2019-04-12T11:13:20.2685568+00:00',
    email: 'm*******g@ayudo.de',
    fullName: 'M********n E***r',
    id: 'cdc817b4-d670-4931-bd4d-97f698609a11',
    invoiceNumber: '2020-0006',
    isReward: true,
    metaType: 'customer',
    number: 50,
    paymentDate: '2020-01-12T06:03:07.172812+00:00',
    percent: 30,
    reward: 14.75,
    total: 59,
  },
  {
    creationDate: '2019-04-12T10:13:20.2685568+00:00',
    email: 'm*******g@ayudo.de',
    fullName: 'M********n E***r',
    id: 'cdc817b4-d670-4931-bd4d-97f698609a11',
    invoiceNumber: '2020-0006',
    isReward: true,
    metaType: 'customer',
    number: 50,
    paymentDate: '2020-01-12T06:03:07.172812+00:00',
    percent: 30,
    reward: 14.75,
    total: 159,
  },
];

const MainAMChart = ({ className }) => {
  const { getCustomers, data: dataCustomers } = useContext(customersContext);
  const { getPayments, data: dataPayouts } = useContext(payoutsContext);
  const { i18n } = useTranslation();
  const chart = useRef(null);

  useEffect(() => {
    getPayments();
    getCustomers();
  }, []);

  const getLang = (lang) => {
    switch (lang) {
      case 'ru':
        return am4langRU;
      case 'en':
        return am4langRU;
      case 'de':
        return am4langDE;
      default:
        return null;
    }
  };

  useLayoutEffect(() => {
    const x = am4core.create('chartdiv', am4charts.XYChart);
    x.language.locale = getLang(i18n.language);
    const dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.minZoomCount = 5;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.groupData = true;
    dateAxis.groupCount = 500;

    const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Payments';
    valueAxis.tooltipText = 'test {valueY}';

    const valueAxis2 = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = 'New customers';
    valueAxis2.renderer.opposite = true;
    valueAxis2.syncWithAxis = valueAxis;

    const series = x.series.push(new am4charts.ColumnSeries());
    series.data = formatDataPayouts(dataPayouts);
    series.name = 'Customers';
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.tooltipText = 'Paymets: {valueY.value}';
    series.yAxis = valueAxis;

    const series2 = x.series.push(new am4charts.ColumnSeries());
    series2.data = formatDataCustomers(dataCustomers);
    series2.name = 'Payments';
    series2.dataFields.dateX = 'date';
    series2.dataFields.valueY = 'value';
    series2.tooltipText = 'New customer: {valueY.value}';
    series2.yAxis = valueAxis2;

    x.scrollbarX = new am4core.Scrollbar();
    x.scrollbarX.align = 'center';
    x.scrollbarX.parent = x.bottomAxesContainer;

    chart.current = x;
    x.cursor = new am4charts.XYCursor();

    return () => {
      x.dispose();
    };
  }, [dataCustomers, dataPayouts]);

  return (
    <div>
      <div id="range" />
      <div id="chartdiv" style={{ width: '100%', height: '500px' }} />
    </div>

  );
};

export default MainAMChart;
