import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Grid, Paper, Link, Typography} from '@material-ui/core';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import { useTranslation } from 'react-i18next';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import MainChart from './components/MainChart/MainChart';
import MainAMChart from './components/MainAMChart/MainAMChart';
import CustomComponent from './components/CustomComponent/App';

import styles from './Dashboard.module.scss';
import customersContext from '../../context/customers/customersContext';
import authContext from '../../context/auth/authContext';
import payoutsContext from '../../context/payouts/payoutsContext';
import Meta from '../../components/Meta/Meta';

const Dashboard = () => {
  const { t } = useTranslation();
  const { totals, getTotal, getCustomers, data: dataCustomers } = useContext(customersContext);
  const { getPayments, data: dataPayouts } = useContext(payoutsContext);
  const { userInfo } = useContext(authContext);
  const [ customers, setCustomers ] = useState([]);
  const [ payments, setPayments ] = useState([]);

  useEffect(() => {
    getSidePayments();
    getSideCustomers();
    getTotal();
  }, []);

  const getSideCustomers = async () => {
    const res = await getCustomers();
    setCustomers(res.data.data);
  }

  const getSidePayments = async () => {
    const res = await getPayments();
    setPayments(res.data.data);
  }

  return (
    <Grid container spacing={4} wrap="wrap">
      <Meta
        title={t('meta.title-dashboard')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={6} lg className={{ dispaly: "flex"}}>
        <Paper className={styles.paper}>
          <InfoBlock
            icon={<AccountCircleOutlinedIcon fontSize="inherit" />}
            title={t('dashboardPage.your-commission')}
            desc={t('dashboardPage.reward-rate', {
              percent1: userInfo?.partnerSetting?.newPercent,
              percent2: userInfo?.partnerSetting?.prolongPercent,
              interpolation: { escapeValue: false },
            })}
            descHtml
            currency={false}
          />
        </Paper>
      </Grid>
      <Grid item xs={6} lg className={styles.gridItem}>
        <Paper className={styles.paper}>
          <InfoBlock
            title={t('dashboardPage.your-sales')}
            value={totals.total}
            desc={<Link to="/payments" component={NavLink}>{t('dashboardPage.total-payments-link')}</Link>}
            currency
          />
        </Paper>
      </Grid>
      <Grid item xs={6} lg className={styles.gridItem}>
        <Paper className={styles.paper}>
          <InfoBlock
            title={t('dashboardPage.total-payouts')}
            value={totals.rewarded}
            desc={<Link to="/reward" component={NavLink}>{t('dashboardPage.total-reward-link')}</Link>}
            currency
          />
        </Paper>
      </Grid>
      <Grid item xs={6} lg className={styles.gridItem}>
        <Paper className={styles.paper}>
          <InfoBlock
            title={t('dashboardPage.total-customers')}
            value={totals.customers}
            desc={<Link to="/customers" component={NavLink}>{t('dashboardPage.total-customers-link')}</Link>}
            currency={false}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={styles.paper}>
            <Typography align="center" variant="h6" component="h2" gutterBottom>{t('MainChartComponent.title')}</Typography>
            <MainChart className={styles.mainChart} dataCustomers={customers} dataPayouts={payments} />
        </Paper>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Paper className={styles.paper}>*/}
      {/*      <Typography align="center" variant="h6" component="h2" gutterBottom>{t('MainChartComponent.title')}</Typography>*/}
      {/*      <MainAMChart className={styles.mainChart} />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default Dashboard;
