import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './App';

// ProviderContexts
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import RewardState from './context/reward/RewardState';
import CustomersState from './context/customers/CustomersState';
import PayoutsState from './context/payouts/PayoutsState';

// main styles
import './index.scss';

// material-ui theme
import customThemeJSON from './theme.json';

const theme = createMuiTheme(JSON.parse(JSON.stringify(customThemeJSON)));

ReactDOM.render(
  <React.StrictMode>
    <AuthState>
      <AlertState>
        <RewardState>
          <CustomersState>
            <PayoutsState>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </PayoutsState>
          </CustomersState>
        </RewardState>
      </AlertState>
    </AuthState>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
