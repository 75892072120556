import React, {
  Fragment, useState, useContext, useEffect,
} from 'react';
import clsx from 'clsx';
import { NavLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider, List, ListItem, Collapse, ListItemText, ListItemIcon, Menu, MenuItem, SvgIcon,
} from '@material-ui/core';
import {
  InsertChart as ChartLineIcon,
  SupervisorAccountOutlined as CustomersIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import { mdiBriefcaseDownloadOutline, mdiBriefcaseClockOutline } from '@mdi/js';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AuthContext from '../../../../context/auth/authContext';
import styles from './MainNavigation.module.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    '&:hover': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid rgba(227,28,94, .3)',
      paddingLeft: 10,
    },
    '&.Mui-selected': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 10,
    },
    '&.active': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 10,
    },
  },
  listItemIcon: {
    minWidth: 46,
  },
  itemText: {
    '& > *': {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
    },
  },
  subItem: {
    paddingLeft: theme.spacing(4),
    '&.logout': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      paddingLeft: 26,
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid rgba(227,28,94, .3)',
    },
    '&.active': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 26,
    },
  },
  logOutIcon: {
    color: theme.palette.error.main,
  },
}));

// TODO Разделить логику пунктов меню при необходимости
const ListItems = withRouter(({
  itemsTop, itemsMid, itemsBottom, isOpen, match,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { logOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if ((match.url === '/profile' || match.url === '/account') && !open) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setOpen(false);
    }

    if ((match.url === '/profile' || match.url === '/account') && !open) {
      setOpen(true);
    }
  }, [isOpen]);

  const handlerLogOut = () => {
    logOut();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlers = (key) => {
    switch (key) {
      case 'profile':
        return (e) => {
          if (isOpen) {
            setOpen(!open);
          } else {
            setAnchorEl(e.currentTarget);
          }
        };
      default:
        return (e) => {
          if (isOpen) {
            setOpen(false);
          }
        };
    }
  };

  return (
    <div>
      <List component="nav">
        {itemsTop.map((item) => (
          <Fragment key={item.key}>
            <ListItem
              active={item.key === 'profile'}
              className={classes.item}
              key={item.key}
              button
              alignItems="center"
              component={item.key !== 'profile' ? NavLink : null}
              to={item.key !== 'profile' ? item.link : ''}
              exact={item.name === 'Dashboard' ? 'true' : 'false'}
              activeClassName="Mui-selected"
              onClick={handlers(item.key)}
              aria-controls={item.key === 'profile' ? 'menu-profile' : null}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} className={classes.itemText} />
              {item.key === 'profile' && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>

            {item.key === 'profile' && (
            <Menu
              id="menu-profile"
              anchorEl={anchorEl}
              keepMounted
              variant="menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >

              <MenuItem
                className={classes.item}
                to="/profile"
                component={NavLink}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                {t('mainMenu.profile-settings')}
              </MenuItem>

              <MenuItem
                className={classes.item}
                to="/account"
                component={NavLink}
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                {t('mainMenu.account-details')}
              </MenuItem>

              <MenuItem
                className={clsx(classes.item, 'logout')}
                onClick={handlerLogOut}
              >
                <ListItemIcon>
                  <ExitToAppIcon className={classes.logOutIcon} />
                </ListItemIcon>
                <span className={styles['logout-menu']}>{t('mainMenu.logout')}</span>
              </MenuItem>

            </Menu>
            )}

            {item.key === 'profile' && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                <ListItem
                  className={classes.subItem}
                  to="/profile"
                  component={NavLink}
                  button
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <SettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('mainMenu.profile-settings')} />
                </ListItem>

                <ListItem
                  className={classes.subItem}
                  to="/account"
                  component={NavLink}
                  button
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('mainMenu.account-details')} />
                </ListItem>

                <ListItem
                  className={clsx(classes.subItem, 'logout')}
                  onClick={handlerLogOut}
                  button
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <ExitToAppIcon className={classes.logOutIcon} />
                  </ListItemIcon>
                  <ListItemText primary={t('mainMenu.logout')} />
                </ListItem>

              </List>
            </Collapse>
            )}
          </Fragment>
        ))}
      </List>

      <Divider />

      <List component="nav">
        {itemsMid.map((item) => (
          <Fragment key={item.key}>
            <ListItem
              active={item.key}
              className={classes.item}
              key={item.key}
              button
              alignItems="center"
              component={NavLink}
              to={item.link}
              exact={item.name === 'Dashboard' ? 'true' : 'false'}
              activeClassName="Mui-selected"
              onClick={handlers(item.key)}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} className={classes.itemText} />
            </ListItem>
          </Fragment>
        ))}
      </List>

      <Divider />

      <List component="nav">
        {itemsBottom.map((item) => (
          <Fragment key={item.key}>
            <ListItem
              active={item.key}
              className={classes.item}
              key={item.key}
              button
              alignItems="center"
              component={item.key === 'collateral' ? 'button' : NavLink}
              href={item.link}
              to={item.key === 'collateral' ? false : item.link}
              target={item.key === 'collateral' ? '_blank' : false}
              exact={item.name === 'Dashboard' ? 'true' : 'false'}
              activeClassName="Mui-selected"
              onClick={handlers(item.key)}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} className={classes.itemText} />
            </ListItem>
          </Fragment>
        ))}
      </List>
    </div>
  );
});

const MainNavigation = ({ className, isOpen, userName }) => {
  const { t, i18n } = useTranslation();

  const navItemsTop = [{
    name: userName,
    link: '/profile',
    key: 'profile',
    icon: <AccountCircleOutlinedIcon />,
  }];

  const navItemsMain = [{
    name: t('mainMenu.dashboard'),
    link: '/',
    key: 'dashboard',
    icon: <ChartLineIcon />,
  }, {
    name: t('mainMenu.customers'),
    link: '/customers',
    key: 'customers',
    icon: <CustomersIcon />,
  }, {
    name: t('mainMenu.payments'),
    link: '/payments',
    key: 'payments',
    icon: <CreditCardOutlinedIcon />,
  }, {
    name: t('mainMenu.reward'),
    link: '/reward',
    key: 'reward',
    icon: <LocalAtmRoundedIcon />,
  },
  //   {
  //   name: t('mainMenu.payments'),
  //   link: '/payments',
  //   key: 'payments',
  //   icon: <PaymentsIcon />,
  // }, {
  //   name: t('mainMenu.reward'),
  //   link: '/reward',
  //   key: 'reward',
  //   icon: <RewardIcon />,
  // }
  ];

  const navItemsBottom = [
  //     {
  //   name: t('mainMenu.newCustomer'),
  //   link: '/new-customer',
  //   key: 'newCustomer',
  //   icon: <PersonAddOutlinedIcon />,
  // },
    {
      name: t('mainMenu.referral'),
      link: '/referral',
      key: 'referral',
      icon: <LinkIcon />,
    },
    {
      name: t('mainMenu.collateral'),
      link: process.env.REACT_APP_MATERIALS_URL,
      key: 'collateral',
      icon: <SvgIcon><svg><path d={mdiBriefcaseDownloadOutline} /></svg></SvgIcon>,
    }, {
      name: t('mainMenu.advertising'),
      link: '/advertising',
      key: 'advertising',
      icon: <DescriptionOutlinedIcon />,
    }];

  return (
    <div className={className}>
      <ListItems
        itemsTop={navItemsTop}
        itemsMid={navItemsMain}
        itemsBottom={navItemsBottom}
        isOpen={isOpen}
      />
    </div>
  );
};

ListItems.propType = {
  itemsTop: PropTypes.array.isRequired,
  itemsMid: PropTypes.array.isRequired,
  itemsBottom: PropTypes.array.isRequired,
  match: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

MainNavigation.propType = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

export default MainNavigation;
