import {
  GET_REWARDS, HIDE_LOADER, SHOW_LOADER, SHOW_LOADER_PDF, HIDE_LOADER_PDF,
} from '../types';

const handlers = {
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_LOADER_PDF]: (state) => ({ ...state, loadingDownloadPDF: true }),
  [HIDE_LOADER_PDF]: (state) => ({ ...state, loadingDownloadPDF: false }),
  [GET_REWARDS]: (state, { payload }) => ({
    ...state,
    loading: false,
    data: payload,
  }),
};

const rewardReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default rewardReducer;
