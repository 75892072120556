import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';


import payoutsContext from '../../context/payouts/payoutsContext';
import customersContext from '../../context/customers/customersContext';

import CustomersTable from './components/CustomersTable/CustomersTable';
import TotalPayments from '../../components/TotalPayments/TotalPayments';
import styles from './Payments.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: 20,
  },
  title: {
    paddingLeft: theme.spacing(2),
  },
}));

const Payments = () => {
  const { t } = useTranslation();
  const { data, getPayments } = useContext(payoutsContext);
  const { totals, getTotal } = useContext(customersContext);
  const classes = useStyles();

  useEffect(() => {
    getPayments();
    getTotal();
  }, []);

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-payments')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={9}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom className={classes.title}>{t('paymetsPage.page-title')}</Typography>
          <Divider className={classes.divider} />
          <CustomersTable data={data} />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={styles.paperTotal} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom className={classes.title}>{t('paymetsPage.page-sub-title')}</Typography>
          <Divider className={classes.divider} />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >

            <TotalPayments
              value={totals}
              desc={t('rewardPage.total-payments')}
            />
          </Grid>

        </Paper>
      </Grid>
    </Grid>
  );
};

export default Payments;
