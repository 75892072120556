import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const LogoIcon = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M 11.132812 0.0507812 C 9.132812 0.242188 7.203125 1.128906 5.746094 2.53125 C 3.695312 4.503906 2.691406 7.285156 3.023438 10.078125 C 3.371094 13.035156 5.070312 15.636719 7.429688 16.828125 C 8.179688 17.207031 8.578125 17.3125 9.328125 17.316406 C 10.226562 17.320312 10.777344 17.109375 11.347656 16.542969 C 11.925781 15.96875 12.21875 15.164062 12.171875 14.296875 C 12.113281 13.21875 11.597656 12.449219 10.625 11.976562 C 9.871094 11.617188 9.160156 11.484375 7.90625 11.484375 C 7.460938 11.484375 7.074219 11.464844 7.039062 11.441406 C 6.921875 11.367188 7.09375 11.160156 8.179688 10.039062 C 9.355469 8.832031 9.453125 8.6875 9.402344 8.292969 C 9.382812 8.171875 9.179688 7.453125 8.945312 6.695312 C 8.457031 5.125 8.433594 5.015625 8.570312 5.015625 C 8.621094 5.015625 9.28125 5.320312 10.039062 5.695312 C 10.898438 6.121094 11.511719 6.398438 11.679688 6.429688 C 11.933594 6.484375 11.953125 6.484375 12.242188 6.335938 C 12.402344 6.257812 12.984375 5.875 13.523438 5.484375 C 15.042969 4.402344 15.007812 4.421875 15.0625 4.566406 C 15.074219 4.601562 14.992188 5.28125 14.878906 6.074219 C 14.625 7.804688 14.621094 8.101562 14.839844 8.375 C 14.984375 8.554688 15.980469 9.332031 16.960938 10.035156 C 17.492188 10.414062 17.582031 10.515625 17.5 10.597656 C 17.46875 10.625 16.789062 10.757812 15.980469 10.898438 C 15.167969 11.039062 14.394531 11.175781 14.253906 11.207031 C 13.777344 11.3125 13.855469 11.132812 12.636719 14.804688 L 11.539062 18.09375 L 18.542969 18.09375 L 19.632812 14.78125 C 20.226562 12.953125 20.746094 11.335938 20.785156 11.179688 C 21.285156 9.148438 21.027344 6.894531 20.085938 5.015625 C 18.402344 1.664062 14.855469 -0.300781 11.132812 0.0507812 Z M 11.132812 0.0507812 " />
      <path d="M 10.25 22 C 9.898438 23.0625 9.609375 23.949219 9.609375 23.96875 C 9.609375 23.984375 11.175781 24 13.09375 24 L 16.570312 24 L 17.21875 22.0625 C 17.570312 21 17.859375 20.113281 17.859375 20.09375 C 17.859375 20.078125 16.292969 20.0625 14.375 20.0625 L 10.898438 20.0625 Z M 10.25 22 " />
    </g>
  </SvgIcon>
);

export const PaypalIcon = (props) => (
  <SvgIcon {...props}>
    <g id="surface1">
      <path
        style={{
          stroke: 'none', 'fill-rule': 'nonzero', fill: 'rgb(1.176471%,66.27451%,95.686275%)', 'fill-opacity': 1,
        }}
        d="M 19.941406 5.519531 C 19.796875 5.433594 19.617188 5.425781 19.464844 5.503906 C 19.3125 5.578125 19.210938 5.726562 19.191406 5.894531 C 19.175781 6.058594 19.148438 6.222656 19.113281 6.394531 C 18.132812 10.839844 16.167969 13 13.101562 13 L 8.601562 13 C 8.363281 13 8.160156 13.167969 8.109375 13.398438 L 6.609375 20.429688 L 6.34375 22.222656 C 6.1875 23.035156 6.722656 23.820312 7.539062 23.972656 C 7.628906 23.992188 7.71875 24 7.808594 24 L 10.820312 24 C 11.511719 24.003906 12.113281 23.53125 12.273438 22.859375 L 13.492188 18 L 16.101562 18 C 19.179688 18 21.308594 15.546875 22.09375 11.085938 C 22.609375 8.964844 21.753906 6.742188 19.941406 5.519531 Z M 19.941406 5.519531 "
      />
      <path
        style={{
          stroke: 'none', 'fill-rule': 'nonzero', fill: 'rgb(15.686275%,20.784314%,57.647059%)', 'fill-opacity': 1,
        }}
        d="M 19 1.78125 C 18.011719 0.660156 16.59375 0.0117188 15.101562 0 L 6.820312 0 C 5.582031 -0.0078125 4.527344 0.898438 4.347656 2.125 L 1.773438 19.277344 C 1.652344 20.097656 2.214844 20.859375 3.035156 20.984375 C 3.109375 20.996094 3.183594 21 3.261719 21 L 7.101562 21 C 7.335938 21 7.542969 20.832031 7.589844 20.601562 L 9.003906 14 L 13.105469 14 C 16.664062 14 19.011719 11.511719 20.09375 6.601562 C 20.136719 6.402344 20.171875 6.207031 20.191406 6.007812 C 20.445312 4.492188 20.007812 2.941406 19 1.78125 Z M 19 1.78125 "
      />
    </g>
  </SvgIcon>
);

export const GooglelIcon = (props) => (
  <SvgIcon {...props}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512">
      <path style={{fill:'#FBBB00'}} d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256
      c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456
      C103.821,274.792,107.225,292.797,113.47,309.408z"/>
      <path style={{fill:'#518EF8'}} d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451
      c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535
      c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"/>
      <path style={{fill:'#28B446'}} d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512
      c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771
      c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"/>
      <path style={{fill:'#F14336'}} d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012
      c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0
      C318.115,0,375.068,22.126,419.404,58.936z"/>
  </svg>
  </SvgIcon>
);

export default LogoIcon;
