import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({
  title, subTitle,
}) => (
  <Helmet>
    <title>
      {' '}
      {title}
      {' '}
      |
      Questionstar -
      {' '}
      {subTitle}
    </title>
  </Helmet>
);

export default Meta;
