import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Button,
  Typography,
  Divider,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import AuthContext from '../../context/auth/authContext';

import ProfileForm from './components/ProfileForm/ProfileForm';

import styles from './Profile.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
}));

const Profile = () => {
  const { t } = useTranslation();
  const {
    saveUser,
    loading,
    userInfo,
  } = useContext(AuthContext);

  const [values, setValues] = useState({
    email: userInfo.email,
    language: userInfo.language,
    company: userInfo.company,
    websiteFaculty: userInfo.websiteFaculty,
  });

  const classes = useStyles();

  const handleSaveForm = () => {
    saveUser(values);
  };

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-profile')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom>{t('userSettings.settings-page-title')}</Typography>
          <Divider className={classes.divider} />

          <ProfileForm values={values} handleSetValues={setValues} />

          <Grid item xs={12}>
            <Button
              disabled={loading}
              className={classes.button}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleSaveForm}
            >
              {t('userSettings.settings-button-save')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
