import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
    Link
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import rewardContext from '../../context/reward/rewardContext';
import authContext from '../../context/auth/authContext';

import CustomersTable from './components/CustomersTable/CustomersTable';
import TotalPayments from '../../components/TotalPayments/TotalPayments';
import styles from './Reward.module.scss';
import customersContext from '../../context/customers/customersContext';
import Meta from '../../components/Meta/Meta';
import getCurrencyIco from '../../utils/getCurrencyIco';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: 20,
  },
  title: {
    paddingLeft: theme.spacing(2),
  },
  submit: {
    marginLeft: 20,
    marginBottom: theme.spacing(2),
  },
}));

const Reward = () => {
  const { t } = useTranslation();
  const {
    data,
    loading,
    getRewards,
    postRewards,
    downloadRewardPDF,
    loadingDownloadPDF,
    showLoaderPDF,
    hideLoaderPDF,
  } = useContext(rewardContext);
  const {
    userInfo: {
      card,
      paymentMethod
    },
  } = useContext(authContext);
  const { totals, getTotal } = useContext(customersContext);
  const [unReward, setUnReward] = useState(totals.unReward);
  const [openDialog, setOpenDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMesssage, setErrorMesssage] = useState('');
  const classes = useStyles();

  const minReward = process.env.REACT_APP_MIN_REWARD || 50;

  useEffect(() => {
    if(+unReward < +minReward) {
      setErrorMesssage(t('rewardPage.reward-dialog-input-error-less'))
    } else if (+unReward > +totals.unReward){
      setErrorMesssage(t('rewardPage.reward-dialog-input-error-more'))
    } else {
      setErrorMesssage('')
    }
  }, [unReward]);

  useEffect(() => {
    getRewards();
    getTotal();
  }, []);

  useEffect(() => {
    setUnReward(totals?.unReward)
  }, [totals]);

  const handleOpenRewardDialog = () => {
    setUnReward(totals?.unReward)
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setUnReward(totals?.unReward)
    setOpenDialog(false);

  };
  const handleCloseSuccessDialog = () => {
    setShowSuccess(false);
  };

  const handleChangeUnReward = (event) => {
    const { value } = event.target;
   if (value >= totals.unReward) {
      setUnReward(totals.unReward);
      return;
    }
    setUnReward(event.target.value);
    return null;
  };

  const handleBlurUnReward = (event) => {
    return;
    const { value } = event.target;
    if (value <= minReward) {
      setUnReward(minReward);
      return;
    }
  };

  const handlePostReward = async () => {
    await postRewards(unReward);
    setOpenDialog(false);
    setShowSuccess(true);
  };

  const getButtonDisabled = () => {
    return totals.unReward < minReward || !card
  }

  const getButtonRewardDisabled = () => {
    return loading || +unReward < +minReward || +unReward > +totals.unReward
  }

  const renderSuccessDialog = () => (
    <Dialog onClose={handleCloseSuccessDialog} aria-labelledby="customized-dialog-title" open={showSuccess}>
      <DialogContent dividers>
        <Typography gutterBottom>
          {t('rewardPage.reward-success-dialog-message', { email: card?.payerID })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseSuccessDialog} color="primary">
          {t('rewardPage.reward-dialog-button-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderDialog = () => (
    <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog} maxWidth="xs" fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
        {t('rewardPage.reward-dialog-title')}
      </DialogTitle>
      <DialogContent dividers>
        <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={unReward}
            type="number"
            min={minReward}
            max={totals.unReward}
            onChange={handleChangeUnReward}
            onBlur={handleBlurUnReward}
            label={t('rewardPage.reward-dialog-input-label')}
            InputProps={{
            startAdornment: <span dangerouslySetInnerHTML={{ __html: `${getCurrencyIco()}&#160;` }} />,
          }}
        />
        <div className={styles.error}>{errorMesssage}</div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialog} color="primary">
          {t('rewardPage.reward-dialog-button-cancel')}
        </Button>
        <Button autoFocus onClick={handlePostReward} color="primary" disabled={getButtonRewardDisabled()}>
          {t('rewardPage.reward-dialog-button-reward')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-reward')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={9}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom className={classes.title}>{t('rewardPage.page-title')}</Typography>
          <Divider className={classes.divider} />
          <CustomersTable
              data={data}
              downloadRewardPDF={downloadRewardPDF}
              loadingDownloadPDF={loadingDownloadPDF}
              showLoaderPDF={showLoaderPDF}
              hideLoaderPDF={hideLoaderPDF}
          />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={styles.paperTotal} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom className={classes.title}>{t('rewardPage.page-sub-title')}</Typography>
          <Divider className={classes.divider} />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >

            <TotalPayments
                className={styles.totalPayments}
                value={totals}
                desc={t('rewardPage.total-payments')} />

            <Button
              disabled={getButtonDisabled()}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleOpenRewardDialog}
            >
              {t('rewardPage.button-payout')}
            </Button>
            {!card && (<div className={styles.payoutCardDesc}>
              {t('rewardPage.payout-card-desc1')}
                <Link to="/account" component={NavLink}>
                  {t('rewardPage.payout-card-link')}
                </Link>
              {paymentMethod !== 'unavailable' ? t('rewardPage.payout-card-desc2') : '.'}
            </div>)}
            <div className={styles.payoutDesc}>{process.env.REACT_APP_LANG === 'ru'
                ? t('rewardPage.payout-desc-ru') : t('rewardPage.payout-desc') }</div>
          </Grid>
        </Paper>
      </Grid>
      {renderDialog()}
      {renderSuccessDialog()}
    </Grid>
  );
};

export default Reward;
