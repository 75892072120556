import React from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Link, SvgIcon,
} from '@material-ui/core';
import { mdiFileExport } from '@mdi/js';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { useTranslation } from 'react-i18next';
import EnhancedTableHead from './components/EnhancedTableHead/EnhancedTableHead';
import CircularProgress from "@material-ui/core/CircularProgress";
import {mdiBriefcaseDownloadOutline} from "@mdi/js";

const useStyles = makeStyles((theme) => ({
  messageEmpty: {
    textAlign: 'center',
    opacity: 0.4,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0',
  },
  messageEmptyIcon: {
    fontSize: 80,
  },
  'color-pending': {
    color: theme.palette.warning.main,
  },
  'color-success': {
    color: theme.palette.success.main,
  },
  'color-error': {
    color: theme.palette.error.main,
  },
  noBreak: {
    whiteSpace: 'nowrap',
  },
  downloadLink: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    justifyContent: 'flex-end'
  },
  downloadLinkIco: {
    marginRight: 10,
    marginTop: -3,
    color: 'rgba(0, 0, 0, 0.4)',
  },
  loaderDownloadPDF: {
    marginRight: 13,
    position: 'relative'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const NumberPDFCell = (props) => {
  const { row, downloadRewardPDF } = props;
  const [showLoader, setShowLoader] = React.useState(false);
  const classes = useStyles();

  const handleLoadingPDF = async (id) => {
    try {
      setShowLoader(true)
      await downloadRewardPDF(id)
      setTimeout(() => {setShowLoader(false)}, 1000)
    } catch (e) {
      setTimeout(() => {setShowLoader(false)}, 1000)

    }
  }

  return <div>
    {row.rewardStatus === 'success'
        ? (
            <Link className={classes.downloadLink} onClick={() => {
              setShowLoader(true)
              handleLoadingPDF(row.id);
            }
            }>
              {showLoader
                  ? <CircularProgress className={classes.loaderDownloadPDF} color="primary" size={20} />
                  : <SvgIcon className={classes.downloadLinkIco}><svg><path d={mdiFileExport} /></svg></SvgIcon>
              }
              {row.number}
            </Link>
        )
        : <span>{row?.number?.length ? row.number : '—'}</span>}
  </div>
}

const CustomersTable = (props) => {
  const { data, downloadRewardPDF } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('creationDate');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const headCells = [
    {
      id: 'creationDate', numeric: false, disablePadding: false, label: t('rewardPage.table-title-request-date'),
    },
    {
      id: 'total', numeric: true, disablePadding: true, label: t('rewardPage.table-title-amount'),
    },
    {
      id: 'rewardStatus', numeric: true, disablePadding: false, label: t('rewardPage.table-title-status'),
    },

    {
      id: 'completionDate', numeric: true, disablePadding: false, label: t('rewardPage.table-title-completion-date'),
    },
    {
      id: 'number', numeric: true, disablePadding: true, label: t('rewardPage.table-title-id'),
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className="material-table">
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          {!!data.length && (
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{moment.tz(row.creationDate, moment.tz.guess()).format('DD.MM.YYYY')}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                      <TableCell align="right" className={classes[`color-${row.rewardStatus}`]}>{t(`rewardPage.status-${row.rewardStatus}`)}</TableCell>
                      <TableCell align="right">{row.completionDate ? moment.tz(row.completionDate, moment.tz.guess()).format('DD.MM.YYYY') : '—'}</TableCell>
                      <TableCell align="right" className={classes.noBreak}>
                        <NumberPDFCell row={row} downloadRewardPDF={downloadRewardPDF}/>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!!data.length && (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      )}
      {!data.length && (
      <div className={classes.messageEmpty}>
        <InboxOutlinedIcon className={classes.messageEmptyIcon} />
        {t('tableComponent.empty')}
      </div>
      )}
    </div>
  );
};

export default CustomersTable;
