import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Link,
} from '@material-ui/core';
import { AlternateEmail } from '@material-ui/icons';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import validate from '../../helpers/validate';
import AuthContext from '../../context/auth/authContext';

import styles from './ForgotPass.module.scss';
import useForm from '../../hooks/useForm';
import useLabelWidth from '../../hooks/useLabelWidth';
import useKeyPress from '../../hooks/useKeyPress';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  divider: {
    marginBottom: '10px',
  },
  gridLang: {
    textAlign: 'right',
  },
  selctLang: {
    fontSize: '12px',
  },
  errorMess: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const ForgotPass = () => {
  const { t } = useTranslation();
  const { recover, loading } = useContext(AuthContext);
  const classes = useStyles();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFormError, setShowFormError] = useState(false);

  const [
    labelEmailWidth,
    labelEmailRef,
  ] = useLabelWidth();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    recover(values.email).then((data) => {
      if (!data.data.success) {
        setShowFormError(true);
      } else {
        setShowSuccessMessage(true);
        setShowFormError(false);
      }
    });
  }, validate, {
    email: '',
  });

  useKeyPress('Enter', handleSubmit);

  if (showSuccessMessage) {
    return (
      <div className={styles.message}>
        <MailOutlineRoundedIcon style={{ fontSize: 40 }} />
        <p dangerouslySetInnerHTML={{ __html: t('forgotPassPage.success-form', { email: values.email }) }} />
        <div style={{ textAlign: 'center' }}>
          <Link to="/sign-in" variant="body2" component={NavLink}>
            {t('forgotPassPage.button-login')}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.paper}>
      <Meta
        title={t('meta.title-forgot-pass')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <div className={classes.form}>
        <FormControl className={classes.formControl} variant="outlined" fullWidth error={!!errors.email}>
          <InputLabel ref={labelEmailRef} htmlFor="input-with-icon-adornment">{t('forgotPassPage.input-label-email')}</InputLabel>
          <OutlinedInput
            value={values.email || ''}
            id="input-with-icon-adornment"
            name="email"
            labelWidth={labelEmailWidth}
            onChange={handleChange}
            startAdornment={(
              <InputAdornment position="start">
                <AlternateEmail color="disabled" />
              </InputAdornment>
                              )}
          />
          <FormHelperText>{errors.email}</FormHelperText>
        </FormControl>
        {showFormError
        && (
        <div className={classes.errorMess}><Alert severity="error" color="error">{t('forgotPassPage.error-form')}</Alert></div>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t('forgotPassPage.button-recover')}
        </Button>
        <Link to="/sign-in" variant="body2" component={NavLink}>
          {t('forgotPassPage.button-login')}
        </Link>
      </div>
    </div>

  );
};

export default ForgotPass;
