import React, { useRef } from 'react';
import qrcode from 'qrcode';
import clsx from 'clsx';
import QRC from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import styles from './QRCode.module.scss';

const QRCode = ({
  className, url, partnerCode,
}) => {
  const { t } = useTranslation();
  const qrRef = useRef(null);
  const handleDownload = () => {
    const opts = {
      type: 'image/png',
      quality: 100,
      width: 1000
    };
    qrcode.toDataURL(url, opts).then((data) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = `${partnerCode}.png`;
      a.click();
    });
  };
  return (
    <div className={clsx(className, styles.main)}>
      <div>
        <QRC
          className={styles.qrCode}
          ref={qrRef}
          value={url}
        />
      </div>
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={handleDownload}
      >
        {t('QRCodeComponent.button')}
      </Button>
    </div>
  );
};

export default QRCode;
