import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Link,
  FormHelperText,
} from '@material-ui/core';
import {
  AlternateEmail, LockOpen, VisibilityOffOutlined, VisibilityOutlined,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context/auth/authContext';
import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';

import useLabelWidth from '../../hooks/useLabelWidth';
import useKeyPress from '../../hooks/useKeyPress';
import SocialLogins from '../../components/SocialLogins/SocialLogins';
import Meta from '../../components/Meta/Meta';
import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  divider: {
    marginBottom: '10px',
  },
  gridLang: {
    textAlign: 'right',
  },
  selectLang: {
    fontSize: '12px',
  },
  errorMess: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const Registration = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, loading, cookieError, setCookieError } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [showFormCookieError, setShowFormCookieError] = useState('');


  const [
    labelPassWidth,
    labelPassRef,
  ] = useLabelWidth();
  const [
    labelEmailWidth,
    labelEmailRef,
  ] = useLabelWidth();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    register(values.email, values.password, values.iAcceptTermOfService).then((data) => {
      if (!data.data.success) {
        setShowFormError(t('registrationPage.error-form'));
      } else {
        setShowFormError('');
      }
    });
  }, validate, {
    password: '',
    email: '',
    iAcceptTermOfService: false,
  });

  useKeyPress('Enter', handleSubmit);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.paper}>
      <Meta
        title={t('meta.title-registration')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <SocialLogins
        cookieError={cookieError}
        googleButtonText={t('registrationPage.button-google')}
        fbButtonText={t('registrationPage.button-facebook')}
        onError={(res) => {
          if (res?.error === 'idpiframe_initialization_failed') {
            setCookieError();
            setShowFormCookieError(t('signInPage.error-social-cookie'));
            return;
          }

          if (res) {
            setShowFormError(t('registrationPage.error-social'));
          } else {
            setShowFormError('');
          }
        }}
      />
      {t('registrationPage.or')}
      <div className={classes.form}>
        <FormControl className={classes.formControl} variant="outlined" fullWidth error={!!errors.email}>
          <InputLabel ref={labelEmailRef} htmlFor="input-with-icon-adornment">{t('registrationPage.input-label-email')}</InputLabel>
          <OutlinedInput
            value={values.email || ''}
            id="input-with-icon-adornment"
            onChange={handleChange}
            name="email"
            labelWidth={labelEmailWidth}
            startAdornment={(
              <InputAdornment position="start">
                <AlternateEmail color="disabled" />
              </InputAdornment>
            )}
          />
          <FormHelperText>{errors.email}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined" error={!!errors.password}>
          <InputLabel ref={labelPassRef} htmlFor="outlined-adornment-password">{t('registrationPage.input-label-password')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={values.password || ''}
            onChange={handleChange}
            name="password"
            labelWidth={labelPassWidth}
            startAdornment={(
              <InputAdornment position="start">
                <LockOpen color="disabled" />
              </InputAdornment>
                              )}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
                              )}
          />
          <FormHelperText>{errors.password}</FormHelperText>
        </FormControl>
        <ConfirmationForm name="iAcceptTermOfService" onChange={handleChange} error={errors.iAcceptTermOfService} />
        {showFormError
        && (
        <div className={classes.errorMess}><Alert severity="error" color="error">{showFormError}</Alert></div>
        )}
        {showFormCookieError
        && (
        <div className={classes.errorMess}><Alert severity="error" color="error">{showFormCookieError}</Alert></div>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="primary"
          disabled={loading}
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t('registrationPage.button-register')}
        </Button>
        <div style={{ textAlign: 'center' }}>
          <Link to="/sign-in" variant="body2" component={NavLink}>
            {t('registrationPage.link-login')}
          </Link>
        </div>
      </div>
    </div>

  );
};

export default Registration;
