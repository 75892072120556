import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import AuthContext from '../../context/auth/authContext';

import PayoutsForm from './components/PayoutsForm/PayoutsForm';
import ContactForm from './components/ContactForm/ContactForm';

import styles from './Account.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  payoutsForm: {
    marginBottom: theme.spacing(6),
  },
}));

const Account = () => {
  const { t } = useTranslation();
  const {
    userInfo,
  } = useContext(AuthContext);

  const [values, setValues] = useState({
    email: userInfo.email,
    paypalEmail: userInfo.email,
    language: userInfo.language,
    company: userInfo.company,
    websiteFaculty: userInfo.websiteFaculty,
    paymentMethod: userInfo.paymentMethod,
  });

  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-account')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Grid item xs={12} className={clsx(classes.formControl, classes.payoutsForm)}>
            <Typography variant="h5" component="h1" gutterBottom>{t('userSettings.account-page-title')}</Typography>
            <Divider className={classes.divider} />

            <PayoutsForm values={values} handleSetValues={setValues} />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <Typography variant="h5" component="h1" gutterBottom>{t('userSettings.account-page-sub-title')}</Typography>
            <Divider className={classes.divider} />

            <ContactForm />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Account;
