import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import QRCode from '../../components/QRCode/QRCode';

import styles from './ReferralLink.module.scss';
import Meta from '../../components/Meta/Meta';
import authContext from '../../context/auth/authContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  referralLink: {
    margin: '40px 0',
    cursor: 'pointer',
    display: 'inline-block',
    '-webkit-user-select': 'all',
    '-moz-user-select': 'all',
    '-ms-user-select': 'all',
    'user-select': 'all',
  },
}));

const ReferralLink = () => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { userInfo: { partnerCode } } = useContext(authContext);
  const classes = useStyles();

  const link = `${process.env.REACT_APP_SITE_URL}/?partner=${partnerCode}`;

  const handlerCopyLink = () => {
    navigator.clipboard.writeText(link);
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-referral-link')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom>{t('referralLinkPage.page-title')}</Typography>
          <Divider className={classes.divider} />

          <Typography>{t('referralLinkPage.click-to-copy')}</Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              arrow
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              title={t('referralLinkPage.link-copied')}
            >
              <Typography
                variant="h6"
                display="inline-block"
                className={classes.referralLink}
                onClick={handlerCopyLink}
              >
                {link}
              </Typography>
            </Tooltip>
          </ClickAwayListener>
          <QRCode url={link} partnerCode={partnerCode} />
          <Typography>{t('referralLinkPage.desc')}</Typography>

        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReferralLink;
