import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';

import customersContext from '../../context/customers/customersContext';

import CustomersTable from './components/CustomersTable/CustomersTable';
import styles from './Customers.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 20,
  },
}));

const Customers = () => {
  const { data, getCustomers } = useContext(customersContext);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <Grid container spacing={4}>
      <Meta
        title={t('meta.title-customers')}
        subTitle={t('mainContainer.logo-desc')}
      />
      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <Typography variant="h5" component="h1" gutterBottom>{t('customersPage.page-title')}</Typography>
          <Divider className={classes.divider} />
          <CustomersTable data={data} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Customers;
