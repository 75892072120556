import { useState, useEffect, useRef } from 'react';

const useLabelWidth = () => {
  const [labelWidth, setLabelEmailWidth] = useState(0);
  const labelRef = useRef(null);

  useEffect(() => {
    if (labelRef.current) setLabelEmailWidth(labelRef.current.offsetWidth + 8);
  });

  return [labelWidth, labelRef];
};

export default useLabelWidth;
