import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from 'react-i18next';
import AuthContext from './context/auth/authContext';
import AlertContext from './context/alert/alertContext';

import MainAuthContainer from './pages/MainAuthContainer/MainAuthContainer';
import MainContainer from './pages/MainContainer/MainContainer';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import Account from './pages/Account/Account';
import SignIn from './pages/SignIn/SignIn';
import Registration from './pages/Registration/Registration';
import ForgotPass from './pages/ForgotPass/ForgotPass';
import ResetPass from './pages/ResetPass/ResetPass';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import ReferralLink from './pages/ReferralLink/ReferralLink';
import Advertising from './pages/Advertising/Advertising';
import Customers from './pages/Customers/Customers';
import NewCustomer from './pages/NewCustomer/NewCustomer';
import Payments from './pages/Payments/Payments';
import Reward from './pages/Reward/Reward';

import Preloader from './components/Preloader/Preloader';


const PrivateRoute = ({ component: Component, ...props }) => {
  const { userAuth } = useContext(AuthContext);
  return (
    <Route
      {...props}
      render={() => (userAuth === true
        ? <MainContainer><Component {...props} /></MainContainer>
        : <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />)}
    />
  );
};

const PublicRoute = ({ component: Component, ...props }) => {
  const { userAuth } = useContext(AuthContext);
  return (
    <Route
      {...props}
      render={() => (userAuth === false
        ? <MainAuthContainer><Component {...props} /></MainAuthContainer>
        : <Redirect to="/" />)}
    />
  );
};

function App() {
  const { t } = useTranslation();
  const {
    checkAuth, userCheck, preLoading,
  } = useContext(AuthContext);
  const {
    isShowAlert, messageAlert, typeAlert, closesAlert,
  } = useContext(AlertContext);

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <title>
          Questionstar -
          {' '}
          {t('mainContainer.logo-desc')}
        </title>
      </Helmet>
      <Preloader show={preLoading} />
      <Snackbar
        open={isShowAlert}
        autoHideDuration={4000}
        onClose={closesAlert}
      >
        <Alert elevation={6} variant="filled" severity={typeAlert}>{messageAlert}</Alert>
      </Snackbar>
      <Switch>
        <PublicRoute path="/sign-in" component={SignIn} />
        <PublicRoute path="/register" component={Registration} />
        <PublicRoute path="/forgot-pass" component={ForgotPass} />
        <PublicRoute path="/reset-pass" component={ResetPass} />
        <Route path="/confirm-email" render={() => (<MainAuthContainer><ConfirmEmail /></MainAuthContainer>)} />
        {userCheck && (<PrivateRoute path="/" exact component={Dashboard} />)}
        {userCheck && (<PrivateRoute path="/profile" component={Profile} />)}
        {userCheck && (<PrivateRoute path="/account" component={Account} />)}
        {userCheck && (<PrivateRoute path="/referral" component={ReferralLink} />)}
        {userCheck && (<PrivateRoute path="/advertising" component={Advertising} />)}
        {userCheck && (<PrivateRoute path="/customers" component={Customers} />)}
        {userCheck && (<PrivateRoute path="/new-customer" component={NewCustomer} />)}
        {userCheck && (<PrivateRoute path="/payments" component={Payments} />)}
        {userCheck && (<PrivateRoute path="/reward" component={Reward} />)}
      </Switch>
    </BrowserRouter>
  );
}

PrivateRoute.propType = {
  component: PropTypes.element.isRequired,
  location: PropTypes.string,
};

PublicRoute.propType = {
  component: PropTypes.element.isRequired,
};

export default App;
