import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export const post = (url, data = {}, options = {}) => axios.post(url, data, options);

export const get = (url, options = {}) => axios.get(url, options);

export const put = (url, data = {}, options = {}) => axios.put(url, data, options);
