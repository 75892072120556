import i18n from 'i18next';

const validate = (values) => {
  const errors = {};

  for (const value in values) {
    if (value === 'firstName'
        || value === 'lastName'
        || value === 'address'
        || value === 'postalCode'
        || value === 'city'
        || value === 'country'
        || value === 'phone') {
      if (!values[value].trim().length) {
        errors[value] = i18n.t('form.error-required');
      }
    }
  }
  if (typeof values.iAcceptTermOfService !== 'undefined') {
    if (!values.iAcceptTermOfService) {
      errors.iAcceptTermOfService = true;
    }
  }
  return errors;
};

export default validate;
